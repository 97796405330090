// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ConstitutionPage-module-main > section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

#ConstitutionPage-module-main > section:first-of-type > h1 {
  font-size: 3rem;
}

#ConstitutionPage-module-main > section:first-of-type {
  height: 730px;
  margin: -30px -30px 0;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100vw;
  max-width: none;
  color: white;
  padding: 0 30px;
  box-sizing: border-box;
}

#ConstitutionPage-module-constitutionText section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#ConstitutionPage-module-main > #ConstitutionPage-module-actionList {
  display: flex;
  flex-direction: row;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ConstitutionPage/ConstitutionPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":["#main > section {\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n\n#main > section:first-of-type > h1 {\n  font-size: 3rem;\n}\n\n#main > section:first-of-type {\n  height: 730px;\n  margin: -30px -30px 0;\n  align-items: center;\n  justify-content: center;\n  background-color: black;\n  width: 100vw;\n  max-width: none;\n  color: white;\n  padding: 0 30px;\n  box-sizing: border-box;\n}\n\n#constitutionText section {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n#main > #actionList {\n  display: flex;\n  flex-direction: row;\n  gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ConstitutionPage-module-main`,
	"constitutionText": `ConstitutionPage-module-constitutionText`,
	"actionList": `ConstitutionPage-module-actionList`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./EventsPage.module.css";

export default function EventsPage() {

  return (
    <main id={styles.main}>
      <section>
        <h1>Events</h1>
      </section>
      <section>
        
      </section>
    </main>
  );

}
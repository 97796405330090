// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MemberButton-module-item > a {
  display: flex;
  text-decoration: none;
  gap: 30px;
  align-items: center;
}

.MemberButton-module-item > a > section:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.MemberButton-module-imageContainer {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  flex-shrink: 0;
  background-color: black;
  overflow: hidden;
}

.MemberButton-module-imageContainer > img {
  width: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/MembershipPage/components/MemberButton/MemberButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".item > a {\n  display: flex;\n  text-decoration: none;\n  gap: 30px;\n  align-items: center;\n}\n\n.item > a > section:last-of-type {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.imageContainer {\n  width: 100px;\n  height: 100px;\n  border-radius: 100%;\n  flex-shrink: 0;\n  background-color: black;\n  overflow: hidden;\n}\n\n.imageContainer > img {\n  width: 100%;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `MemberButton-module-item`,
	"imageContainer": `MemberButton-module-imageContainer`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MobileMenu-module-menu {
  display: flex;
  background-color: black;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  padding: 30px;
  box-sizing: border-box;
}

#MobileMenu-module-menu > section {
  display: flex;
  justify-content: right;
}

#MobileMenu-module-menu a {
  color: white;
  text-decoration: none;
}

#MobileMenu-module-closeButton {
  background-color: transparent;
  padding: 0;
}

#MobileMenu-module-menu ul {
  padding: 0;
  margin: 0;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 700px) {
  
  #MobileMenu-module-menu {
    display: none;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/components/Header/components/MobileMenu/MobileMenu.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,UAAU;EACV,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,SAAS;EACT,SAAS;EACT,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE;IACE,aAAa;EACf;;AAEF","sourcesContent":["#menu {\n  display: flex;\n  background-color: black;\n  flex-direction: column;\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  z-index: 3;\n  padding: 30px;\n  box-sizing: border-box;\n}\n\n#menu > section {\n  display: flex;\n  justify-content: right;\n}\n\n#menu a {\n  color: white;\n  text-decoration: none;\n}\n\n#closeButton {\n  background-color: transparent;\n  padding: 0;\n}\n\n#menu ul {\n  padding: 0;\n  margin: 0;\n  gap: 30px;\n  display: flex;\n  flex-direction: column;\n}\n\n@media screen and (min-width: 700px) {\n  \n  #menu {\n    display: none;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `MobileMenu-module-menu`,
	"closeButton": `MobileMenu-module-closeButton`
};
export default ___CSS_LOADER_EXPORT___;

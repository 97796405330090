// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  display: flex;
  padding: 30px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  --text-copyright: #b2b9cc;
  z-index: 2;
  align-items: center;
  height: 84px;
}

header.Header-module-background {
  background-color: black;
}

header a {
  color: white;
}

#Header-module-left > a {
  display: flex;
  gap: 15px;
  align-items: center;
}

#Header-module-left > a > img {
  width: 50px;
}

#Header-module-left > a > span {
  display: none;
}

#Header-module-right ul {
  display: none;
}

#Header-module-right > button {
  padding: 0;
  background-color: transparent;
}

@media screen and (min-width: 700px) {
  
  #Header-module-right > button {
    display: none;
  }

  #Header-module-right > ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    gap: 30px;
  }

  #Header-module-right a {
    text-decoration: none;
  }
  
  #Header-module-right > ul > li:last-of-type > a {
    background-color: var(--college-democrats-blue);
    color: white;
    padding: 5px 10px;
  }
  
}

@media screen and (min-width: 1240px) {
  
  #Header-module-left > a {
    text-decoration: none;
  }

  #Header-module-left > a > span {
    display: initial;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,8BAA8B;EAC9B,yBAAyB;EACzB,UAAU;EACV,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;;EAEE;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,UAAU;IACV,SAAS;IACT,SAAS;EACX;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,+CAA+C;IAC/C,YAAY;IACZ,iBAAiB;EACnB;;AAEF;;AAEA;;EAEE;IACE,qBAAqB;EACvB;;EAEA;IACE,gBAAgB;EAClB;;AAEF","sourcesContent":["header {\n  display: flex;\n  padding: 30px;\n  position: fixed;\n  width: 100%;\n  box-sizing: border-box;\n  justify-content: space-between;\n  --text-copyright: #b2b9cc;\n  z-index: 2;\n  align-items: center;\n  height: 84px;\n}\n\nheader.background {\n  background-color: black;\n}\n\nheader a {\n  color: white;\n}\n\n#left > a {\n  display: flex;\n  gap: 15px;\n  align-items: center;\n}\n\n#left > a > img {\n  width: 50px;\n}\n\n#left > a > span {\n  display: none;\n}\n\n#right ul {\n  display: none;\n}\n\n#right > button {\n  padding: 0;\n  background-color: transparent;\n}\n\n@media screen and (min-width: 700px) {\n  \n  #right > button {\n    display: none;\n  }\n\n  #right > ul {\n    list-style: none;\n    display: flex;\n    padding: 0;\n    margin: 0;\n    gap: 30px;\n  }\n\n  #right a {\n    text-decoration: none;\n  }\n  \n  #right > ul > li:last-of-type > a {\n    background-color: var(--college-democrats-blue);\n    color: white;\n    padding: 5px 10px;\n  }\n  \n}\n\n@media screen and (min-width: 1240px) {\n  \n  #left > a {\n    text-decoration: none;\n  }\n\n  #left > a > span {\n    display: initial;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `Header-module-background`,
	"left": `Header-module-left`,
	"right": `Header-module-right`
};
export default ___CSS_LOADER_EXPORT___;

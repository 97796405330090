// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#NotFoundPage-module-main {
  background-color: black;
  padding: 30px;
  min-height: 730px;
  color: white;
  align-items: center;
  justify-content: center;
}

#NotFoundPage-module-main > img {
  max-width: 500px;
  aspect-ratio: 1;
  border-radius: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["#main {\n  background-color: black;\n  padding: 30px;\n  min-height: 730px;\n  color: white;\n  align-items: center;\n  justify-content: center;\n}\n\n#main > img {\n  max-width: 500px;\n  aspect-ratio: 1;\n  border-radius: 100%;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `NotFoundPage-module-main`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeRuler-module-timeRuler {
  display: flex;
  flex-direction: row;
  gap: 30px;
  overflow-x: auto;
  padding: 0 calc(50% - 46px);
}`, "",{"version":3,"sources":["webpack://./src/pages/MembershipPage/components/TimeRuler/TimeRuler.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":[".timeRuler {\n  display: flex;\n  flex-direction: row;\n  gap: 30px;\n  overflow-x: auto;\n  padding: 0 calc(50% - 46px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeRuler": `TimeRuler-module-timeRuler`
};
export default ___CSS_LOADER_EXPORT___;

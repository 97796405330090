// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MembershipList-module-list {
  display: flex;
  padding: 0;
  flex-direction: column;
  margin: 0;
  list-style: none;
  gap: 30px;
  justify-content: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/MembershipPage/components/MembershipList/MembershipList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,SAAS;EACT,qBAAqB;AACvB","sourcesContent":[".list {\n  display: flex;\n  padding: 0;\n  flex-direction: column;\n  margin: 0;\n  list-style: none;\n  gap: 30px;\n  justify-content: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `MembershipList-module-list`
};
export default ___CSS_LOADER_EXPORT___;

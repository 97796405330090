// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#HomePage-module-videoContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  margin: -30px;
  height: 100vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 0 30px;
  box-sizing: border-box;
}

#HomePage-module-videoContainer > h1 {
  font-size: 3rem;
  line-height: 3.5rem;
}

#HomePage-module-videoContainer > :not(video) {
  z-index: 1;
  padding: 0 30px;
}

#HomePage-module-videoContainer > video {
  width: 100%;
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["#videoContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  justify-content: center;\n  align-items: center;\n  background-color: black;\n  color: white;\n  margin: -30px;\n  height: 100vh;\n  position: relative;\n  overflow: hidden;\n  text-align: center;\n  padding: 0 30px;\n  box-sizing: border-box;\n}\n\n#videoContainer > h1 {\n  font-size: 3rem;\n  line-height: 3.5rem;\n}\n\n#videoContainer > :not(video) {\n  z-index: 1;\n  padding: 0 30px;\n}\n\n#videoContainer > video {\n  width: 100%;\n  opacity: 0.4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoContainer": `HomePage-module-videoContainer`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: var(--college-democrats-blue);
  --text-copyright: #b2b9cc;
}

#Footer-module-footerContent {
  display: flex;
  color: white;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

#Footer-module-footerContent > p {
  color: var(--text-copyright);
}

#Footer-module-footerContent > section {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.Footer-module-listContainer {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.Footer-module-listContainer > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Footer-module-listContainer > ul > li > a {
  color: inherit;
  text-decoration: none;
}

@media screen and (min-width: 820px) {
  
  #Footer-module-footerContent > section {
    flex-direction: row;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;;EAEE;IACE,mBAAmB;EACrB;;AAEF","sourcesContent":["footer {\n  background-color: var(--college-democrats-blue);\n  --text-copyright: #b2b9cc;\n}\n\n#footerContent {\n  display: flex;\n  color: white;\n  flex-direction: column;\n  padding: 30px;\n  gap: 30px;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n#footerContent > p {\n  color: var(--text-copyright);\n}\n\n#footerContent > section {\n  display: flex;\n  gap: 30px;\n  flex-direction: column;\n}\n\n.listContainer {\n  display: flex;\n  gap: 15px;\n  flex-direction: column;\n}\n\n.listContainer > ul {\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.listContainer > ul > li > a {\n  color: inherit;\n  text-decoration: none;\n}\n\n@media screen and (min-width: 820px) {\n  \n  #footerContent > section {\n    flex-direction: row;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContent": `Footer-module-footerContent`,
	"listContainer": `Footer-module-listContainer`
};
export default ___CSS_LOADER_EXPORT___;

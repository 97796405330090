// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --college-democrats-blue: #203d95;
}

html {
  font-size: 16px;
  line-height: 1.5rem;
  font-family: "Roboto Serif", serif;
}

body {
  margin: 0;
}

h1 {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.5rem;
}

a {
  color: var(--college-democrats-blue);
}

p, h1 {
  margin: 0;
  padding: 0;
}

main {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px
}

input {
  padding: 15px;
}

button {
  font-size: inherit;
  font-family: inherit;
  padding: 5px 15px;
  border-radius: 15px;
  border: none;
  background-color: var(--college-democrats-blue);
  color: white;
  cursor: pointer;
}

button.secondary {
  border: 1px solid var(--college-democrats-blue);
  box-sizing: border-box;
  background-color: transparent;
  color: var(--college-democrats-blue);
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,+CAA+C;EAC/C,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,+CAA+C;EAC/C,sBAAsB;EACtB,6BAA6B;EAC7B,oCAAoC;AACtC","sourcesContent":[":root {\n  --college-democrats-blue: #203d95;\n}\n\nhtml {\n  font-size: 16px;\n  line-height: 1.5rem;\n  font-family: \"Roboto Serif\", serif;\n}\n\nbody {\n  margin: 0;\n}\n\nh1 {\n  font-weight: 500;\n  font-size: 1.8rem;\n  line-height: 2.5rem;\n}\n\na {\n  color: var(--college-democrats-blue);\n}\n\np, h1 {\n  margin: 0;\n  padding: 0;\n}\n\nmain {\n  padding: 30px;\n  display: flex;\n  flex-direction: column;\n  gap: 30px\n}\n\ninput {\n  padding: 15px;\n}\n\nbutton {\n  font-size: inherit;\n  font-family: inherit;\n  padding: 5px 15px;\n  border-radius: 15px;\n  border: none;\n  background-color: var(--college-democrats-blue);\n  color: white;\n  cursor: pointer;\n}\n\nbutton.secondary {\n  border: 1px solid var(--college-democrats-blue);\n  box-sizing: border-box;\n  background-color: transparent;\n  color: var(--college-democrats-blue);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
